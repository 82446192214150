<template>
  <div>
    <button @click="successSound1">Başarılı 1</button>
    <button @click="successSound2">Başarılı 2</button>
    <button @click="warningSound1">Dikkat 1</button>
    <button @click="warningSound2">Dikkat 2</button>
    <button @click="dangerSound1">Başarısız 1</button>
    <button @click="dangerSound2">Başarısız 2</button>

    <i class="bi bi-calendar2-plus"></i>
  </div>
</template>

<script>
import soundMixins from "@/mixins/soundMixins";

export default {
  mixins: [soundMixins],
  methods: {
    successSound1() {
      this.success1Sound.play();
    },
    successSound2() {
      this.success2Sound.play();
    },
    warningSound1() {
      this.warning1Sound.play();
    },
    warningSound2() {
      this.warning2Sound.play();
    },
    dangerSound1() {
      this.danger1Sound.play();
    },
    dangerSound2() {
      this.danger2Sound.play();
    },
  },
}
</script>
